import React from "react"
import Helmet from "react-helmet"
import SEO from "../components/seo"
import "../styles/main.scss"
import OgImage from "../images/og-image.jpg"

const Bestallning = ({ location }) => (
  <>
    <SEO title="Beställning" ogImage={OgImage} pagePath={location.pathname} />
    <Helmet
      bodyAttributes={{
        class: "background-grey",
      }}
    >
      {/*
      <script
        type="text/javascript"
        src="https://form.jotform.com/jsform/220782487625363"
      ></script>
    */}
    </Helmet>
  </>
)

export default Bestallning
